<template>
    <footer class="text-center text-white py-5 mx-auto">

        <h1>The Lobby</h1>
        <div class="social-media-icons">
        <!-- Your existing icon elements go here -->
          <a href="https://www.facebook.com/profile.php?id=61571354956587" target="_blank">
          <img src="../assets/fb-icon.svg" alt="Icon 1" class="icon">
          </a>
          <a href="https://www.instagram.com/thelobbyvasagatan/" target="_blank">
          <img src="../assets/insta-icon.svg" alt="Icon 2" class="icon">
          </a>
        </div>

        <div class="icons">
        <div class="icon-column">
            <div class="icon-row">
            <img src="../assets/phone-icon.svg" alt="Icon 4" class="icon">
            <p>070-736 88 99</p>
            </div>
            <div class="icon-row">
            <img src="../assets/mail-icon.svg" alt="Icon 4" class="icon">
            <p>info@thelobbysthlm.nu</p>
            </div>
            <div class="icon-row">
            <img src="../assets/pin-icon.svg" alt="Icon 4" class="icon">
            <p>Vasagatan 44, 111 20 Stockholm</p>
            </div>
        </div>

        <div class="info-column">
            <p>Öppettider:</p>
            <p>Måndag till Torsdag 14:00 - 01:00</p>
            <p>Fredag 12:00-01:00</p>
            <p>Lördag 11:00-01:00</p>
            <p>Söndag 12:00-01:00</p>
            
        </div>

        </div>
        <div class="border-line"></div>

        <div>
          © 2023 The Lobby - Alla rättigheter reserverade.
          <br/>
          Webbdesign av Romina från PR Agenturen Sverige AB
        </div>
    </footer>
</template>

<style>
footer {
    background-color: #1A7515;
}

  .border-line {
    width: 80%; /* Adjust the width of the border as needed */
    height: 2px;
    background-color: #fff;
    margin: 20px auto; /* Adjust the margin to center the border */
  }

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .icon-column {
    width: 50%;
    display: flex;
        flex-direction: column;
    max-width: 400px; /* Adjust max-width as needed */
    padding: 20px;
    justify-content:center;
    align-items: center;
  }

  .info-column {
    width: 50%;
    max-width: 400px; /* Adjust max-width as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .icon-row {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 10px;
  }

  .icons p {
    padding-top: 10px;
    font-size: 20px;
  }

    @media (max-width: 800px) {
    .icons {
      flex-direction: column;
    }

    .icon-column,
    .info-column {
      width: 100%;
    }
  }



  .social-media-icons img:hover {
      filter: brightness(0.8);
      cursor:pointer;
  }

</style>